<template>
  <b-card class="program-update-tab-card">
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <div class="d-flex align-items-center justify-content-end">
          <div>
            <action-button
              v-b-toggle.add-resource-category-right
              variant="primary"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Category</span>
            </action-button>
            <action-button
              v-b-toggle.add-resource-right
              variant="primary"
              style="margin-left: 10px"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
                class="mr-50"
              />
              <span class="text-nowrap">Add Library Item</span>
            </action-button>

            <b-sidebar
              id="add-resource-right"
              title="Add Library Item"
              bg-variant="white"
              right
              backdrop
              shadow
              style="min-width: 600px !important"
              width="50%"
            >
              <div>
                <add-resource
                  :is-program-resource="true"
                  :is-champion-library="true"
                  @resourceAdded="onRefreshItems"
                />
              </div>
            </b-sidebar>

            <!-- Resource Category sidebar -->
            <b-sidebar
              id="add-resource-category-right"
              title="Add Resource Category"
              bg-variant="white"
              right
              backdrop
              shadow
              style="min-width: 600px !important;"
              width="600px"
            >
              <div>
                <add-resource-category
                  :is-program-resource-category="true"
                  @resourceCategoryAdded="onRefreshItems"
                />
              </div>
            </b-sidebar>
          </div>
        </div>
      </b-col>
    </b-row>

    <resources-list
      ref="resourcesList"
      @edit="editResource"
    />

    <b-sidebar
      id="edit-resource-right"
      title="Edit Library Item"
      bg-variant="white"
      right
      backdrop
      shadow
      style="min-width: 600px !important"
      width="50%"
    >
      <div>
        <edit-resource
          :is-program-resource="true"
          :is-champion-library="true"
          :resource-id="resourceId"
          @resourceUpdated="onRefreshItems"
        />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, BSidebar, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddResource from "@/views/components/forms/resources/AddResource.vue";
import EditResource from "@/views/components/forms/resources/EditResource.vue";
import ResourcesList from "./ResourcesList.vue";
import AddResourceCategory from "@/views/components/forms/resources/AddResourceCategory.vue";
import ActionButton from "@/views/components/common/ActionButton.vue";

export default {
  components: {
    ActionButton,
    BCard,
    BButton,
    BRow,
    BCol,
    AddResource,
    EditResource,
    ResourcesList,
    BSidebar,
    AddResourceCategory
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    program: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dir: false,
      rows: [],
      resourceId: null,
      showModal: false,
    };
  },
  methods: {
    onRefreshItems() {
      this.$refs.resourcesList.loadItems();
    },
    editResource(id) {
      this.resourceId = id;
    },
    handleShowModal() {
      this.showModal = true;
    },
    hiddenModal(isShow) {
      this.showModal = isShow;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  font-weight: 400 !important;
}
table.vgt-table td {
  vertical-align: middle;
}
</style>
